import { useCallback, useEffect, useState } from "react";
import micoach, { CandidateApi } from "micoach-api";

export function useAchievements() {
  const [achievements, setAchievements] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadEvents = useCallback(async () => {
    setLoading(true);
    try {
      // TODO: remove filter later
      let { data } = await CandidateApi.listCandidateEvents();
      data = data.filter(
        (event) =>
          !event.isTraining && event.type !== micoach.EventTypeEnum.TechTechTalk
      );
      setAchievements(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadEvents();
  }, [loadEvents]);

  return [loading, achievements];
}

export function useAchievement(eventId) {
  const [achievement, setAchievement] = useState();
  const [loading, setLoading] = useState(false);

  const loadEvent = useCallback(async () => {
    if (eventId) {
      setLoading(true);
      try {
        const { data } = await CandidateApi.getCandidateEvent(eventId);
        setAchievement(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [eventId]);

  useEffect(() => {
    loadEvent();
  }, [loadEvent]);

  return [loading, achievement, setAchievement];
}
