import { useParams } from "react-router";

import Achievement from "Components/Candidate/Achievement";
import StateCard from "Components/Common/StateCard";
import Loading from "Components/Loading";
import { usePublicCandidateEvent } from "Hooks/useCandidateEvents";
import { assets } from "constants.js";

const ViewPublicAchievement = () => {
  const { candidateId, eventId } = useParams();
  const { candidateEvent, loadingCandidateEvent } = usePublicCandidateEvent(
    candidateId,
    eventId
  );

  if (loadingCandidateEvent) {
    return <Loading />;
  }

  if (!candidateEvent) {
    return (
      <StateCard imageUrl={assets.ZERO_STATE} title="Achievement not found!" />
    );
  }

  return <Achievement achievement={candidateEvent} isPublic />;
};

export default ViewPublicAchievement;
