import { Link } from "react-router-dom";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

import ActionCard from "Components/Candidate/ActionCard";

const TakeTrainingCard = (props) => {
  return (
    <div className="TakeTrainingCard">
      <Link to="/trainings">
        <ActionCard
          title="Take a training"
          subtitle="Link"
          icon={faGraduationCap}
        />
      </Link>
    </div>
  );
};

export default TakeTrainingCard;
