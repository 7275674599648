import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import {
  mapToSelectOptions,
  sortByProperty,
  transformFormValuesToFilters,
} from "utils";

import FilterButton from "Components/Common/FilterButton";
import CustomSelect from "Components/CustomSelect";
import { CANDIDATE_EMPLOYMENT, sources } from "constants.js";

import styles from "Components/Common/styles/Filters.module.css";

const KanbanFilterButton = (props) => {
  const { filterValues, recruiters, tags, loading, onApplyFilters } = props;

  const [showPopover, setShowPopover] = useState(false);

  const { handleSubmit, register, reset, control } = useForm();

  const handleClickReset = () => {
    setShowPopover(false);

    onApplyFilters?.({});
  };

  const handleClickApply = (formValues) => {
    if (formValues.employment) {
      const employmentType =
        formValues.employment === CANDIDATE_EMPLOYMENT.TRAINEE.value
          ? CANDIDATE_EMPLOYMENT.TRAINEE
          : CANDIDATE_EMPLOYMENT.FTE;

      const newEmployment = [
        {
          value: employmentType.value,
          label: employmentType.label,
        },
      ];

      formValues.employment = newEmployment;
    }

    const newFilters = transformFormValuesToFilters(formValues);

    setShowPopover(false);
    onApplyFilters?.(newFilters);
  };

  useEffect(() => {
    reset({
      source: filterValues.source ?? [],
      recruiter: filterValues.recruiter ?? [],
      tags: filterValues.tags ?? [],
      employment:
        filterValues.employment?.length > 0
          ? filterValues.employment[0].value
          : null,
    });
  }, [filterValues, reset]);

  return (
    <FilterButton
      show={showPopover}
      setShowPopover={setShowPopover}
      onClickReset={handleClickReset}
    >
      <Form onSubmit={handleSubmit(handleClickApply)}>
        <Form.Group controlId="employment" className="form-group">
          <Form.Label>Employment</Form.Label>
          <div className={styles.Radios}>
            <Form.Check
              type="radio"
              name="employment"
              id="employment-trainee"
              value={CANDIDATE_EMPLOYMENT.TRAINEE.value}
              label={CANDIDATE_EMPLOYMENT.TRAINEE.label}
              defaultChecked={
                filterValues.employment?.[0]?.label ===
                CANDIDATE_EMPLOYMENT.TRAINEE.label
              }
              {...register("employment")}
              inline
            />
            <Form.Check
              type="radio"
              name="employment"
              id="employment-fte"
              value={CANDIDATE_EMPLOYMENT.FTE.value}
              label={CANDIDATE_EMPLOYMENT.FTE.label}
              defaultChecked={
                filterValues.employment?.[0]?.label ===
                CANDIDATE_EMPLOYMENT.FTE.label
              }
              {...register("employment")}
              inline
            />
          </div>
        </Form.Group>
        <Form.Group controlId="source" className="form-group">
          <Form.Label>Source</Form.Label>
          <CustomSelect
            isMulti
            name="source"
            defaultValue={filterValues?.source}
            control={control}
            options={sortByProperty(
              mapToSelectOptions(sources, "key", "name"),
              "label",
              "asc"
            )}
          />
        </Form.Group>
        <Form.Group controlId="recruiter" className="form-group">
          <Form.Label>TA Member</Form.Label>
          <CustomSelect
            isMulti
            name="recruiter"
            defaultValue={filterValues?.recruiter}
            control={control}
            options={
              recruiters &&
              sortByProperty(
                mapToSelectOptions(recruiters, "_id", "name"),
                "label",
                "asc"
              )
            }
          />
        </Form.Group>
        <Form.Group controlId="tags" className="form-group">
          <Form.Label>Tags (Exact match filter)</Form.Label>
          <CustomSelect
            isMulti
            name="tags"
            aria-label="tags"
            defaultValue={filterValues?.tags}
            control={control}
            options={
              tags &&
              sortByProperty(
                mapToSelectOptions(tags, "_id", "label"),
                "label",
                "asc"
              )
            }
          />
        </Form.Group>
        <div className={styles.Buttons}>
          <Button variant="secondary" onClick={() => setShowPopover(false)}>
            Close
          </Button>
          <Button disabled={loading} variant="primary" type="submit">
            Apply
          </Button>
        </div>
      </Form>
    </FilterButton>
  );
};

KanbanFilterButton.propTypes = {
  filterValues: PropTypes.shape({
    source: PropTypes.array,
    employment: PropTypes.array,
    recruiter: PropTypes.array,
    tags: PropTypes.array,
  }),
  recruiters: PropTypes.array,
  tags: PropTypes.array,
  loading: PropTypes.bool,
  onApplyFilters: PropTypes.func,
};

KanbanFilterButton.defaultProps = {
  filterValues: {},
  recruiters: [],
  tags: [],
  loading: false,
};

export default KanbanFilterButton;
