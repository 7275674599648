import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { faDownload, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShareModal } from "@itj-micoach/micoach-common";
import { saveAs } from "file-saver";

import BackendService from "Services/BackendService";

import "Components/Candidate/styles/Achievement.css";
import actionButtonStyles from "Components/Common/styles/ActionButton.module.css";

const Achievement = (props) => {
  const { achievement, isPublic } = props;

  const [showShareModal, setShowShareModal] = useState(false);
  const [downloadingCertificate, setDownloadingCertificate] = useState(false);

  const handleClickDownloadPdf = async () => {
    setDownloadingCertificate(true);
    try {
      const blob = await BackendService.downloadCandidateEventCertificate(
        achievement._id
      );

      saveAs(
        new Blob([blob]),
        `Certificate ${achievement.name} - ${achievement.candidateName}.pdf`
      );
    } catch (error) {
      toast.error(
        "Error! An error occurred while generating the certificate, please try again."
      );
    } finally {
      setDownloadingCertificate(false);
    }
  };

  const path = `/candidate/${achievement?.candidateId}/achievements/${achievement?._id}`;
  const url = `${process.env.REACT_APP_HOST}${path}`;

  if (!achievement) {
    return <h1>Achievement not found!</h1>;
  }

  return (
    <>
      <ShareModal
        show={showShareModal}
        title="Share Achievement"
        description={achievement.name}
        url={url}
        centered
        onClose={() => setShowShareModal(false)}
        onUrlCopied={() => toast.info("Link copied to clipboard")}
      />
      <div className="Achievement">
        {achievement.coverImageUrl && (
          <div className="CoverImage">
            <img
              src={achievement.coverImageUrl}
              alt={`${achievement.name} cover`}
            />
          </div>
        )}

        <div className="Content">
          {achievement.companyLogoUrl && (
            <div className="CompanyLogo">
              <img
                src={achievement.companyLogoUrl}
                alt={`${achievement.companyName} logo`}
              />
            </div>
          )}
          <div>Present this certificate to</div>
          <div className="UserName">{achievement.candidateName}</div>
          <div>{achievement.certificateMessage}</div>
          <div className="EventName">{achievement.name}</div>
          <div>Authorized by</div>
          <div className="Signature">
            {achievement.certificateAuthorizers?.map?.((authorizer) => (
              <div className="Sign" key={authorizer.name}>
                <img
                  src={authorizer.signatureImageUrl}
                  alt={`${authorizer.name}'s signature`}
                />
                <div className="Name">{authorizer.name}</div>
                <div className="Title">{authorizer.title}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="Footer">
          {!isPublic && (
            <>
              <div className="Points">
                <div className="Circle">{achievement.points}</div>
                <span>Points earned</span>
              </div>

              <div className="Download">
                <div className="Circle">
                  <Button
                    variant="link"
                    onClick={handleClickDownloadPdf}
                    disabled={downloadingCertificate}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </div>
                <span>PDF file</span>
              </div>
            </>
          )}

          {achievement.badgeImageUrl && (
            <div className="Badge">
              <img
                src={achievement.badgeImageUrl}
                alt={`${achievement.name} badge`}
              />
              <span>Badge earned</span>
            </div>
          )}
          {!isPublic && (
            <div className="Share">
              <div className="Circle">
                <Button variant="link" onClick={() => setShowShareModal(true)}>
                  <div className={actionButtonStyles.Container}>
                    <FontAwesomeIcon
                      icon={faShare}
                      className={actionButtonStyles.Icon}
                    />
                  </div>
                </Button>
              </div>
              <span>Share achievement</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Achievement.propTypes = {
  achievement: PropTypes.object.isRequired,
  isPublic: PropTypes.bool,
};

Achievement.defaultProps = {
  isPublic: false,
};

export default Achievement;
