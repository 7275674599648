import { useHistory } from "react-router-dom";
import micoach from "micoach-api";

import AdminItemCard from "Components/Admin/AdminItemCard";
import Restricted from "Components/Common/Restricted";
import { assets } from "constants.js";

const SECTIONS = [
  {
    title: "Email templates",
    description:
      "Automate your work by creating email templates that efficient your days",
    imageUrl: assets.ADMIN_EMAIL_TEMPLATE,
    path: "email-templates",
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    title: "Skills",
    description:
      "Keep the talent at the top of the industry by updating the skills required per role",
    imageUrl: assets.ADMIN_SKILL,
    path: "skills",
    roles: [
      micoach.UserRoleEnum.AdminRecruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    title: "Roles",
    description:
      "Define the roles of the IT industry that will be part of the platform",
    imageUrl: assets.ADMIN_ROLE,
    path: "roles",
    roles: [
      micoach.UserRoleEnum.AdminRecruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    title: "Tags",
    description:
      "Manage the pre-defined attributes for related data in miCoach database",
    imageUrl: assets.ADMIN_TAG,
    path: "tags",
    roles: [micoach.UserRoleEnum.AdminRecruiter],
  },
];

const Dashboard = () => {
  const history = useHistory();

  return (
    <div className="AdminDashboard">
      <h1 className="ScreenTitle">Admin dashboard</h1>

      <div className="d-flex flex-wrap">
        {SECTIONS.map((section) => (
          <Restricted key={section.title} roles={section.roles}>
            <AdminItemCard
              title={section.title}
              description={section.description}
              imageUrl={section.imageUrl}
              onClick={() => history.push(`admin/${section.path}`)}
            />
          </Restricted>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
