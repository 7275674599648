import micoach from "micoach-api";

import blankLayout from "Layouts/BlankLayout";
import AddContact from "Screens/AddContact";
import AdminDashboard from "Screens/Admin/Dashboard";
import EmailTemplates from "Screens/Admin/EmailTemplates";
import Roles from "Screens/Admin/Roles";
import Skills from "Screens/Admin/Skills";
import Tags from "Screens/Admin/Tags";
import Dashboard from "Screens/Candidate/Dashboard";
import ListAchievements from "Screens/Candidate/ListAchievements";
import ListTasks from "Screens/Candidate/ListTasks";
import ProfileBuilder from "Screens/Candidate/ProfileBuilder";
import UploadDocuments from "Screens/Candidate/UploadDocuments";
import ViewAchievement from "Screens/Candidate/ViewAchievement";
import ViewPublicAchievement from "Screens/Candidate/ViewPublicAchievement";
import ListCandidateHistorical from "Screens/CandidateHistorical/ListCandidateHistorical";
import EditContact from "Screens/EditContact";
import EvaluationPeriodComplete from "Screens/Evaluation/EvaluationPeriodComplete";
import Evaluation from "Screens/Evaluation/Skill/Evaluation";
import EvaluationDashboard from "Screens/Evaluation/Skill/EvaluationDashboard";
import EvaluationPeriod from "Screens/Evaluation/Skill/EvaluationPeriod";
import ListCoachees from "Screens/Evaluation/Skill/ListCoachees";
import PerformanceEvaluationPeriod from "Screens/Evaluation/Skill/PerformanceEvaluationPeriod";
import ViewCoacheeSkillEvaluation from "Screens/Evaluation/Skill/ViewCoacheeSkillEvaluation";
import ViewSkillEvaluation from "Screens/Evaluation/Skill/ViewSkillEvaluation";
import ListHiringEventInterviews from "Screens/HiringEvent/ListHiringEventInterviews";
import ListHiringEvents from "Screens/HiringEvent/ListHiringEvents";
import Home from "Screens/Home";
import ListJobPositions from "Screens/Job/ListJobPositions";
import ListJobTemplates from "Screens/Job/ListJobTemplates";
import KanbanBoard from "Screens/Kanban/KanbanBoard";
import KanbanBoardV2 from "Screens/Kanban/KanbanBoardV2";
import ListCompanies from "Screens/ListCompanies";
import ListProspects from "Screens/ListProspects";
import PrivacyPolicy from "Screens/Public/PrivacyPolicy";
import ListMyReferrals from "Screens/Refer/ListMyReferrals";
import ListReferrals from "Screens/Refer/ListReferrals";
import CandidateApplicationProcess from "Screens/Report/CandidateApplicationProcess";
import CandidateBecomeEmployee from "Screens/Report/CandidateBecomeEmployee";
import CompanyApplicationProcess from "Screens/Report/CompanyApplicationProcess";
import ListReports from "Screens/Report/ListReports";
import RecruiterApplicationProcess from "Screens/Report/RecruiterApplicationProcess";
import ReferredCandidates from "Screens/Report/ReferredCandidates";
import ViewCandidate from "Screens/ViewCandidate";
import ViewCompany from "Screens/ViewCompany";
import ViewPrescreening from "Screens/ViewPrescreening";
import ViewProfile from "Screens/ViewProfile";

const routes = [
  {
    path: "/",
    component: Home,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/achievements/:id",
    component: ViewAchievement,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/achievements",
    component: ListAchievements,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/admin/email-templates",
    component: EmailTemplates,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/admin/roles",
    component: Roles,
    roles: [
      micoach.UserRoleEnum.AdminRecruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    path: "/admin/skills",
    component: Skills,
    roles: [
      micoach.UserRoleEnum.AdminRecruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    path: "/admin/tags",
    component: Tags,
    roles: [micoach.UserRoleEnum.AdminRecruiter],
  },
  {
    path: "/admin",
    component: AdminDashboard,
    roles: [
      micoach.UserRoleEnum.Recruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    path: "/application-process/:id/prescreening",
    component: ViewPrescreening,
    roles: [
      micoach.UserRoleEnum.Recruiter,
      micoach.UserRoleEnum.ExternalRecruiter,
    ],
  },
  {
    path: "/candidate/historical",
    component: ListCandidateHistorical,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/candidate/view/:id",
    component: ViewCandidate,
    roles: [
      micoach.UserRoleEnum.Recruiter,
      micoach.UserRoleEnum.HumanResources,
      micoach.UserRoleEnum.ExternalRecruiter,
      micoach.UserRoleEnum.Client,
    ],
  },
  {
    path: "/candidate/:candidateId/achievements/:eventId",
    component: ViewPublicAchievement,
    roles: ["public", micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/coachees/:id/evaluations/skills",
    component: ViewCoacheeSkillEvaluation,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/coachees",
    component: ListCoachees,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/companies",
    component: ListCompanies,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/company/:id/contact/add",
    component: AddContact,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/company/contact/edit/:id",
    component: EditContact,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/company/view/:id",
    component: ViewCompany,
    roles: [micoach.UserRoleEnum.Recruiter],
  },

  {
    path: "/dashboard",
    component: Dashboard,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/evaluation-period/performance",
    component: PerformanceEvaluationPeriod,
    roles: [
      micoach.UserRoleEnum.HumanResources,
      micoach.UserRoleEnum.SiteLeader,
      micoach.UserRoleEnum.Coach,
    ],
  },
  {
    path: "/evaluation-period/:id/complete",
    component: EvaluationPeriodComplete,
    roles: ["public", micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/evaluation-period",
    component: EvaluationPeriod,
    roles: [
      micoach.UserRoleEnum.HumanResources,
      micoach.UserRoleEnum.SiteLeader,
      micoach.UserRoleEnum.Coach,
    ],
  },
  {
    path: "/evaluation/skill/dashboard",
    component: EvaluationDashboard,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/evaluation/skill/view",
    component: ViewSkillEvaluation,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/evaluation/skill",
    component: Evaluation,
    roles: ["public", micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/hiring-events/:id/interviews",
    component: ListHiringEventInterviews,
    roles: [micoach.UserRoleEnum.Recruiter, micoach.UserRoleEnum.Client],
  },
  {
    path: "/hiring-events",
    component: ListHiringEvents,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/job-positions",
    component: ListJobPositions,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/job-templates",
    component: ListJobTemplates,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/kanban",
    component: KanbanBoard,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/kanban-v2",
    component: KanbanBoardV2,
    roles: [micoach.UserRoleEnum.ExternalRecruiter],
  },
  {
    path: "/my-referrals",
    component: ListMyReferrals,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    roles: ["public", micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/profile",
    component: ViewProfile,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/profile-builder",
    component: ProfileBuilder,
    roles: [micoach.UserRoleEnum.Candidate],
    layout: blankLayout,
  },
  {
    path: "/prospects",
    component: ListProspects,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/referrals",
    component: ListReferrals,
    roles: [micoach.UserRoleEnum.HumanResources],
  },
  {
    path: "/reports/application-process/candidate",
    component: CandidateApplicationProcess,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/reports/application-process/company",
    component: CompanyApplicationProcess,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/reports/application-process/history",
    component: RecruiterApplicationProcess,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/reports/candidate-become-employee",
    component: CandidateBecomeEmployee,
    roles: [
      micoach.UserRoleEnum.Recruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    path: "/reports/referred-candidates",
    component: ReferredCandidates,
    roles: [micoach.UserRoleEnum.Recruiter],
  },
  {
    path: "/reports",
    component: ListReports,
    roles: [
      micoach.UserRoleEnum.Recruiter,
      micoach.UserRoleEnum.HumanResources,
    ],
  },
  {
    path: "/tasks",
    component: ListTasks,
    roles: [micoach.UserRoleEnum.Candidate],
  },
  {
    path: "/upload-documents",
    component: UploadDocuments,
    roles: ["public", micoach.UserRoleEnum.Candidate],
  },
];

export default routes;
