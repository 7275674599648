import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import {
  ConfirmModal,
  SurveyModal,
  SurveyTypeEnum,
  useSurvey,
} from "@itj-micoach/micoach-common";
import micoach from "micoach-api";

import AchievementsSummary from "Components/Candidate/AchievementsSummary";
import ActionList from "Components/Candidate/ActionList";
import EarnedBadgesCard from "Components/Candidate/EarnedBadge/EarnedBadgesCard";
import EventList from "Components/Candidate/EventList";
import ImageBanner from "Components/Candidate/ImageBanner";
import PrincipalAchievementsList from "Components/Candidate/PrincipalAchievementsList";
import RecentActivityCard from "Components/Candidate/RecentActivity/RecentActivityCard";
import AlertContainer from "Components/Common/AlertContainer";
import CarouselWrapper from "Components/Common/CarouselWrapper";
import JobsByProfile from "Components/Job/JobsByProfile";
import UserPointsRankingCard from "Components/Ranking/UserPointsRankingCard";
import ReferralModal from "Components/Refer/ReferralModal";
import { useActions } from "Hooks/useActions";
import { useCandidateEvents } from "Hooks/useCandidateEvents";
import { useDocuments } from "Hooks/useDocuments";
import { useEvents } from "Hooks/useEvents";
import { useJobs } from "Hooks/useJobs";
import { useUsersRankinkgByPoints } from "Hooks/useRanking";
import { usePublicRoles } from "Hooks/useRoles";
import { useSuggestedJobs } from "Hooks/useSuggestions";
import { useUserPoints } from "Hooks/useUsers";
import { banners, MICOACH_API_URL } from "constants.js";
import {
  getDateOfCurrentWeekday,
  getDateOfLastWeekday,
  sumByProperty,
} from "utils.js";

import "Screens/Candidate/styles/Dashboard.css";

const Dashboard = () => {
  const ACTION_LIMIT = 4;
  const BADGES_PER_ROW = 4;
  const UPCOMING_EVENT_LIMIT = 3;
  const TRAINING_LIMIT = 1;
  const USER_RANKING_LIMIT = 3;

  const { keycloak } = useKeycloak();
  const candidateId = keycloak.tokenParsed?.candidateId;
  const userId = keycloak?.tokenParsed?.sub;
  const today = new Date();
  const startDate = getDateOfLastWeekday(today, 1);
  const endDate = getDateOfCurrentWeekday(today, 0);

  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showThanksConfirmModal, setShowThanksConfirmModal] = useState(false);

  const { candidateEvents, loadingCandidateEvents } = useCandidateEvents();
  const { ranking, loadingRanking } = useUsersRankinkgByPoints(
    USER_RANKING_LIMIT,
    userId
  );
  const { userPoints, loadingUserPoints } = useUserPoints(
    userId,
    startDate,
    endDate
  );
  const { actions, loadingActions } = useActions(candidateId);
  const { events: trainingsEvents, loadingEvents: loadingTrainigs } = useEvents(
    {
      isTraining: true,
      limit: TRAINING_LIMIT,
      sort: "desc",
    }
  );
  const { events: upcomingEvents, loadingEvents } = useEvents({
    upcoming: true,
    limit: UPCOMING_EVENT_LIMIT,
  });
  const { roles } = usePublicRoles();
  const { documents } = useDocuments(candidateId);
  const { suggestedJobs, loadingSuggestedJobs } = useSuggestedJobs();
  const { jobs, loadJobs } = useJobs();

  const {
    getAvailableSurveys,
    availableSurveys,
    saveSurveyAnswers,
    loadingSaveSurveyAnswers,
  } = useSurvey({
    basePath: MICOACH_API_URL,
    session: {
      accessToken: keycloak?.token,
      user: {
        id: userId,
      },
    },
  });
  const npsSurvey = availableSurveys?.[0];
  const history = useHistory();

  const earnedPoints = sumByProperty(userPoints, "points");
  const points = actions.reduce((accumulator, action) => {
    return accumulator + action.points;
  }, 0);
  const achievements = candidateEvents.filter(
    (event) =>
      !event.isTraining && event.type !== micoach.EventTypeEnum.TechTechTalk
  );
  const achievementsWithBadge = achievements.filter(
    (achievement) => achievement.badgeImageUrl
  );
  const hours = sumByProperty(candidateEvents, "hours");
  const certifications = achievements.length;

  const carouselItems = [
    <ImageBanner
      image={banners.CAREER_PATH}
      alt={banners.CAREER_PATH.alt}
      key={banners.CAREER_PATH.alt}
      onClick={() => (window.location.href = "/career-path")}
    />,
  ];

  const handleSaveSurveyAnswers = (answers, isClosing) => {
    try {
      saveSurveyAnswers(answers, npsSurvey._id, !isClosing);
    } catch (error) {
      console.error(error);
    } finally {
      setShowSurveyModal(false);

      if (!isClosing) {
        setShowThanksConfirmModal(true);
      }
    }
  };

  useEffect(() => {
    loadJobs();
  }, [loadJobs]);

  useEffect(() => {
    getAvailableSurveys(SurveyTypeEnum.Nps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (npsSurvey) {
      setShowSurveyModal(true);
    }
  }, [npsSurvey]);

  return (
    <>
      <div className="Dashboard">
        <Row>
          <Col md={8}>
            <h1 className="ScreenTitle ">Hi {keycloak.tokenParsed?.name}</h1>
            <section className="mt-3">
              <CarouselWrapper items={carouselItems} />
            </section>
            <section>
              <AchievementsSummary
                points={points}
                certifications={certifications}
                hours={hours}
                loading={loadingCandidateEvents}
              />
            </section>
            <section>
              <h2 className="Title mb-3">Your available certificates</h2>
              <PrincipalAchievementsList
                achievements={achievements}
                loading={loadingCandidateEvents}
              />
            </section>
            <section className="my-4">
              <EarnedBadgesCard
                achievements={achievementsWithBadge}
                loading={loadingCandidateEvents}
                badgesPerRow={BADGES_PER_ROW}
              />
            </section>
            <section className="my-4">
              <RecentActivityCard
                actions={actions?.slice(0, ACTION_LIMIT)}
                loading={loadingActions}
              />
            </section>
            <section className="mt-3">
              <div className="TitleContainer">
                <h2 className="Title">Upcoming events</h2>
                <a href="/events" className="SeeAll">
                  See all
                </a>
              </div>
              <EventList events={upcomingEvents} loading={loadingEvents} />
            </section>
          </Col>
          <Col md={4}>
            <section className="mt-2">
              <div className="RequestButtons">
                <Button
                  variant="secondary"
                  className="fs-13 RequestAssesment"
                  onClick={() => history.push("evaluation/skill/dashboard")}
                >
                  Request assesment
                </Button>
                <Button
                  variant="primary"
                  className="fs-13 ReferFriend"
                  onClick={() => setShowReferralModal(true)}
                >
                  Refer a friend
                </Button>
              </div>
            </section>
            <section className="mb-3">
              <UserPointsRankingCard
                ranking={ranking}
                points={earnedPoints}
                loading={loadingRanking || loadingUserPoints}
              />
            </section>
            <section>
              <ActionList documents={documents} />
            </section>
            <section>
              <EventList
                events={trainingsEvents}
                isTraining
                loading={loadingTrainigs}
              />
            </section>
            <section>
              <JobsByProfile
                jobs={suggestedJobs}
                loading={loadingSuggestedJobs}
              />
            </section>
          </Col>
        </Row>
      </div>

      <ConfirmModal
        title="Thanks for your time!"
        show={showThanksConfirmModal}
        backdrop="static"
        centered
        small
        hideCancelButton
        hideConfirmButton
        onClose={() => setShowThanksConfirmModal(false)}
      >
        <span>Your feedback has been received.</span>
      </ConfirmModal>

      <SurveyModal
        show={showSurveyModal}
        backdrop="static"
        survey={npsSurvey}
        isSaving={loadingSaveSurveyAnswers}
        onSave={handleSaveSurveyAnswers}
        onClose={(answers) => handleSaveSurveyAnswers(answers, true)}
      />

      <ReferralModal
        show={showReferralModal}
        isPublic={false}
        roles={roles}
        jobPositions={jobs}
        onClose={() => setShowReferralModal(false)}
      />

      <AlertContainer />
    </>
  );
};

export default Dashboard;
