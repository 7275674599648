import { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { UserRoleEnum } from "@micoach/js-sdk";
import { ApplicationProcessApi, CandidateApi } from "micoach-api";

import ApplicationAddModal from "Components/ApplicationAddModal";
import ApplicationHireModal from "Components/ApplicationHireModal";
import ApplicationOfferAcceptedModal from "Components/ApplicationOfferAcceptedModal";
import CandidateAddModal from "Components/CandidateAddModal";
import AlertContainer from "Components/Common/AlertContainer";
import SelectedFiltersV2 from "Components/Common/SelectedFiltersV2";
import StateCard from "Components/Common/StateCard";
import DiscardModal from "Components/DiscardModal";
import DocumentRequestModal from "Components/DocumentRequestModal";
import EmailSendModal from "Components/EmailSendModal";
import KanbanColumn from "Components/Kanban/KanbanColumn";
import KanbanFilterButton from "Components/Kanban/KanbanFilterButton";
import Loading from "Components/Loading";
import SearchBar from "Components/SearchBar";
import UploadDocumentModal from "Components/UploadDocumentModal";
import { useFilters, useSelectedFilters } from "Hooks/useFilters";
import { useKanban } from "Hooks/useKanban";
import { useTags } from "Hooks/useTags";
import { useUsersByRole } from "Hooks/useUsers";
import {
  applicationProcessStatus,
  assets,
  CANDIDATE_EMPLOYMENT,
  candidateStatus,
  checklistTypes,
  DOCUMENT_REQUEST_ORIGINS,
  kanbanColumns,
  PROCESSES,
} from "constants.js";
import {
  filterCandidateStatus,
  getBooleanParamValue,
  getCandidateStatusFromStep,
  getStepFromCandidateStatus,
  isEarlyStep,
  setLocalStorageItem,
} from "utils.js";

import "Screens/styles/KanbanBoard.css";

const DEFAULT_OFFER_PROCESS_DOCUMENTS = [
  checklistTypes.OFFICIAL_ID.key,
  checklistTypes.PROOF_TAX_STATUS.key,
  checklistTypes.CURP.key,
  checklistTypes.IMSS_NUMBER.key,
  checklistTypes.CURRENT_BENEFITS_FORM.key,
  checklistTypes.WORK_REFERENCES_FORM.key,
];

const DEFAULT_OFFER_ACEPTED_DOCUMENTS = [
  checklistTypes.BIRTH_CERTIFICATE.key,
  checklistTypes.PROOF_ADDRESS.key,
  checklistTypes.PAY_STUB_BANK_ACCOUNT.key,
  checklistTypes.NEW_EMPLOYEE_FORM.key,
  checklistTypes.ID_PHOTO.key,
  checklistTypes.EDUCATIONAL_CERTIFICATE.key,
  checklistTypes.DECLARACION_CREDITOS_ACTIVOS.key,
];

const KanbanBoard = () => {
  const [allColumns, setAllColumns] = useState(
    getBooleanParamValue(null, "allColumns")
  );
  const [search, setSearch] = useState("");
  const [showDocumentRequestModal, setShowDocumentRequestModal] =
    useState(false);
  const [candidateChecklist, setCandidateChecklist] = useState([]);
  const [documentRequestOrigin, setDocumentRequestOrigin] = useState(
    DOCUMENT_REQUEST_ORIGINS.MANUAL_PROCESS
  );
  const [defaultCheckedItems, setDefaultCheckedItems] = useState([]);

  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { users: recruiters } = useUsersByRole({
    role: UserRoleEnum.Recruiter,
  });
  const { filters, setFilters, removeFilter } = useFilters();
  const { selectedFilters } = useSelectedFilters(filters);
  const { tags } = useTags();

  const userId = keycloak.subject;

  const {
    createColumns,
    loading,
    error: kanbanError,
    columns,
    setColumns,
  } = useKanban();

  const getQuery = useCallback((data) => {
    const { filters } = data;
    const query = {};

    if (filters.source?.length > 0) {
      query.source = filters.source.map((source) => source.value);
    }

    if (filters.employment?.length > 0) {
      query.trainee =
        filters.employment[0].value === CANDIDATE_EMPLOYMENT.TRAINEE.value;
    }

    if (filters.recruiter?.length > 0) {
      query.recruiter = filters.recruiter.map((recruiter) => recruiter.value);
    }

    if (filters.tags?.length > 0) {
      query.tagId = filters.tags.map((tag) => tag.value);
    }

    data.search && (query.search = data.search);
    data.status && (query.status = data.status);

    return query;
  }, []);

  // Show Do not contact / Hired columns
  const handleShowAllColumns = () => {
    setLocalStorageItem("allColumns", !allColumns);
    setAllColumns(!allColumns);
  };

  // SearchBar
  const handleSearch = (data) => {
    setSearch(data.search);
  };

  /*************************************************
   *                 Kanban section                *
   *************************************************/

  // States
  const [lastSourceColumnIndex, setLastSourceColumnIndex] = useState(-1);
  const [lastSourceCardIndex, setLastSourceCardIndex] = useState(-1);
  const [lastDestinationColumnIndex, setLastDestinationColumnIndex] =
    useState(-1);
  const [lastDestinationCardIndex, setLastDestinationCardIndex] = useState(-1);
  const [lastDestinationColumn, setLastDestinationColumn] = useState("");
  const [lastCandidate, setLastCandidate] = useState(null);
  const [isDiscarding, setIsDiscarding] = useState(false);

  // Helpers
  const moveCard = (
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    try {
      const newColumns = [...columns];

      // Candidate to move
      const candidate = columns[sourceColumnIndex].candidates[sourceCardIndex];

      // Delete candidate from original position
      newColumns[sourceColumnIndex].candidates.splice(sourceCardIndex, 1);

      // Move candidate to new position
      newColumns[destinationColumnIndex].candidates.splice(
        destinationCardIndex,
        0,
        candidate
      );

      // Set state values
      setColumns(newColumns);
      setLastSourceColumnIndex(sourceColumnIndex);
      setLastSourceCardIndex(sourceCardIndex);
      setLastDestinationColumnIndex(destinationColumnIndex);
      setLastDestinationCardIndex(destinationCardIndex);
      setLastDestinationColumn(newColumns[destinationColumnIndex].key);
      setLastCandidate(candidate);
    } catch (error) {
      console.error(error);
    }
  };

  const undoMoveCard = (
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    // Move to the original position
    moveCard(
      destinationColumnIndex ?? lastDestinationColumnIndex,
      destinationCardIndex ?? lastDestinationCardIndex,
      sourceColumnIndex ?? lastSourceColumnIndex,
      sourceCardIndex ?? lastSourceCardIndex
    );
  };

  const updateCard = (candidate, columnIndex, cardIndex) => {
    const newColumns = [...columns];
    newColumns[columnIndex ?? lastDestinationColumnIndex].candidates[
      cardIndex ?? lastDestinationCardIndex
    ] = candidate;

    // Set state values
    setColumns(newColumns);
    setLastCandidate(candidate);
  };

  const removeCard = (sourceColumnIndex, sourceCardIndex) => {
    try {
      const newColumns = [...columns];

      // Delete candidate from original position
      newColumns[sourceColumnIndex].candidates.splice(sourceCardIndex, 1);

      // Set state values
      setColumns(newColumns);
      setLastSourceColumnIndex(-1);
      setLastSourceCardIndex(-1);
      setLastDestinationColumnIndex(-1);
      setLastDestinationCardIndex(-1);
      setLastDestinationColumn("");
      setLastCandidate(null);
    } catch (error) {
      console.error(error);
    }
  };

  const saveStep = async (candidate, step, movement) => {
    try {
      // Save or update the step in the DB
      const {
        data: { steps },
      } = await ApplicationProcessApi.createOrUpdateStep(
        candidate?.applicationProcess?._id,
        {
          step,
        }
      );

      const updatedCandidate = { ...candidate };
      updatedCandidate.applicationProcess.steps = steps;

      if (step === kanbanColumns.HIRED.key) {
        updatedCandidate.applicationProcess.status =
          applicationProcessStatus.completed;
      }

      updateCard(
        { ...updatedCandidate, status: getCandidateStatusFromStep(step) },
        movement.destinationColumnIndex,
        movement.destinationCardIndex
      );
    } catch (error) {
      // Move to the original position
      // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
      moveCard(
        movement.destinationColumnIndex,
        movement.destinationCardIndex,
        movement.sourceColumnIndex,
        movement.sourceCardIndex
      );
      toast.error("Error. Unable to move the candidate, try again.");
    }
  };

  // ##### Handlers Drag End #####
  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Column's info
    const sourceColumnIndex = columns.findIndex(
      (column) => column.key === source.droppableId
    );
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === destination.droppableId
    );

    const destinationColumn = destination.droppableId;

    // Card's (Candidate) info
    const sourceCardIndex = source.index;
    const destinationCardIndex = destination.index;
    const candidate = columns[sourceColumnIndex].candidates[sourceCardIndex];

    moveCard(
      sourceColumnIndex,
      sourceCardIndex,
      destinationColumnIndex,
      destinationCardIndex
    );

    if (destinationColumn === kanbanColumns.BLOCKED.key) {
      await handleDragEndOnBlocked(
        candidate,
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      return;
    }

    if (destinationColumn === kanbanColumns.LEADS.key) {
      await handleDragEndOnLeads(
        candidate,
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      return;
    }

    if (destinationColumn === kanbanColumns.OFFER_ACCEPTED.key) {
      handleDragEndOnOfferAccepted(
        candidate,
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      return;
    }

    if (destinationColumn === kanbanColumns.HIRED.key) {
      handleDragEndOnHired(
        candidate,
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      return;
    }

    if (destinationColumn === kanbanColumns.TECHNICAL_INTERVIEW.key) {
      setShowApplicationModal(true);
      if (candidate.applicationProcess) {
        setUpdateAP(true);
      }
      return;
    }

    if (destinationColumn === kanbanColumns.OFFER_PROCESS.key) {
      await handleDragEndOnOfferProcess({
        candidate,
        destinationColumn,
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex,
      });
      return;
    }

    if (
      kanbanColumns[destinationColumn].isStep &&
      !candidate.applicationProcess
    ) {
      setShowApplicationModal(true);
      return;
    }

    if (
      kanbanColumns[destinationColumn].isStep &&
      !isEarlyStep(kanbanColumns[destinationColumn].key) &&
      !candidate.applicationProcess.position
    ) {
      setUpdateAP(true);
      setShowApplicationModal(true);
      return;
    }

    // Save the step in the DB
    await saveStep(candidate, destinationColumn, {
      sourceColumnIndex,
      sourceCardIndex,
      destinationColumnIndex,
      destinationCardIndex,
    });
  };

  const handleDragEndOnBlocked = async (
    candidate,
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    if (candidate.applicationProcess) {
      setShowDiscardModal(true);
    } else {
      try {
        // Update candidate status
        const { data: updatedCandidate } = await CandidateApi.updateCandidate(
          candidate._id,
          {
            status: candidateStatus.BLOCKED.key,
            statusUpdatedAt: new Date(),
          }
        );

        updateCard(
          updatedCandidate,
          destinationColumnIndex,
          destinationCardIndex
        );
      } catch (error) {
        // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
        undoMoveCard(
          sourceColumnIndex,
          sourceCardIndex,
          destinationColumnIndex,
          destinationCardIndex
        );
        toast.error("Error. Unable to move the candidate, try again.");
      }
    }
  };

  const handleDragEndOnLeads = async (
    candidate,
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    try {
      if (candidate.applicationProcess) {
        if (candidate.applicationProcess.recruiter !== userId) {
          const error = new Error(
            "You are not allowed to reject this candidate."
          );
          error.type = "RECRUITER_NOT_ALLOWED";
          throw error;
        }

        setShowDiscardModal(true);
      } else {
        // Update candidate status and info
        const newCandidateInfo = {
          status: candidateStatus.LEAD.key,
          statusUpdatedAt: new Date(),
        };

        const { data: updatedCandidate } = await CandidateApi.updateCandidate(
          candidate._id,
          newCandidateInfo
        );

        updateCard(
          updatedCandidate,
          destinationColumnIndex,
          destinationCardIndex
        );
      }
    } catch (error) {
      // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
      undoMoveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );

      toast.error(
        error.type === "RECRUITER_NOT_ALLOWED"
          ? error.message
          : "Error. Unable to move the candidate, try again."
      );
    }
  };

  const handleDragEndOnOfferProcess = async ({
    candidate,
    destinationColumn,
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex,
  }) => {
    if (
      candidate.applicationProcess &&
      candidate.applicationProcess?.position
    ) {
      await saveStep(candidate, destinationColumn, {
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex,
      });

      setDocumentRequestOrigin(DOCUMENT_REQUEST_ORIGINS.OFFER_PROCESS);
      setDefaultCheckedItems(DEFAULT_OFFER_PROCESS_DOCUMENTS);

      await getCandidateChecklist(candidate._id);
      setShowDocumentRequestModal(true);
    } else {
      undoMoveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );

      toast.error("Error. Candidate has no active hiring process or position.");
    }
  };

  const getCandidateChecklist = async (candidateId) => {
    const {
      data: { checklist },
    } = await CandidateApi.getCandidate(candidateId);

    setCandidateChecklist(checklist);
  };

  const handleDragEndOnOfferAccepted = (
    candidate,
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    if (candidate.applicationProcess) {
      setShowOfferAcceptedModal(true);
    } else {
      undoMoveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      toast.error("Error. Candidate has no active hiring process.");
    }
  };

  const handleDragEndOnHired = async (
    candidate,
    sourceColumnIndex,
    sourceCardIndex,
    destinationColumnIndex,
    destinationCardIndex
  ) => {
    if (
      candidate.applicationProcess &&
      (!candidate.applicationProcess.firstDayOfWorkAt ||
        !candidate.applicationProcess.position)
    ) {
      setShowHireModal(true);
    } else if (candidate.applicationProcess) {
      await saveStep(candidate, kanbanColumns.HIRED.key, {
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex,
      });
    } else {
      // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
      undoMoveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      toast.error("Error. Candidate has no active hiring process.");
    }
  };

  // ##### Discard #####
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const handleClickDiscard = (step, cardIndex) => {
    const sourceColumnIndex = columns.findIndex(
      (column) => column.key === step
    );
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === kanbanColumns.LEADS.key
    );
    const sourceCardIndex = cardIndex;
    const destinationCardIndex = 0;

    moveCard(
      sourceColumnIndex,
      sourceCardIndex,
      destinationColumnIndex,
      destinationCardIndex
    );

    setLastCandidate((prevCandidate) => {
      return { ...prevCandidate, status: getCandidateStatusFromStep(step) };
    });
    setShowDiscardModal(true);
  };

  const handleCloseDiscard = () => {
    undoMoveCard();
    setShowDiscardModal(false);
  };

  const handleSubmitDiscard = async (formValues) => {
    try {
      setIsDiscarding(true);

      const newCandidateStatus =
        lastDestinationColumn === kanbanColumns.BLOCKED.key
          ? candidateStatus.BLOCKED.key
          : candidateStatus.LEAD.key;

      if (lastCandidate.applicationProcess) {
        await ApplicationProcessApi.cancelApplicationProcess(
          lastCandidate.applicationProcess?._id,
          {
            candidateStatus: newCandidateStatus,
            discardReason: formValues.discardReason,
            emailTemplateId: formValues.emailTemplateId,
          }
        );
      }

      updateCard({
        ...lastCandidate,
        status: newCandidateStatus,
        applicationProcess: null,
      });

      toast.success("Success! The candidate has been rejected.");
    } catch (error) {
      console.error(error);
      undoMoveCard();
      toast.error("Error! Unable to reject the candidate, try again.");
    } finally {
      setShowDiscardModal(false);
      setIsDiscarding(false);
    }
  };

  // ##### OfferAccepted #####
  const [showOfferAcceptedModal, setShowOfferAcceptedModal] = useState(false);

  const handleCloseOfferAccepted = () => {
    undoMoveCard();
    setShowOfferAcceptedModal(false);
  };

  const handleSavedOfferAccepted = async (applicationProcess) => {
    if (lastDestinationColumnIndex !== lastSourceColumnIndex) {
      updateCard({
        ...lastCandidate,
        applicationProcess,
        status: candidateStatus.OFFER_ACCEPTED.key,
      });
    } else {
      removeCard(lastSourceColumnIndex, lastSourceCardIndex);
    }

    toast.success("Success! The candidate's offer has been set as accepted.");
    setShowOfferAcceptedModal(false);
  };

  const handleSavedOfferAcceptedError = (message) => {
    toast.error(message);
    undoMoveCard();
    setShowOfferAcceptedModal(false);
  };

  // ##### Hire #####
  const [showHireModal, setShowHireModal] = useState(false);

  const handleClickHire = async (candidate, step, cardIndex) => {
    const sourceColumnIndex = columns.findIndex(
      (column) => column.key === step
    );
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === kanbanColumns.HIRED.key
    );
    const sourceCardIndex = cardIndex;
    const destinationCardIndex = 0;

    if (destinationColumnIndex >= 0) {
      moveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
    } else {
      moveCard(
        sourceColumnIndex,
        sourceCardIndex,
        sourceColumnIndex,
        sourceCardIndex
      );
    }

    setLastCandidate((prevCandidate) => {
      return { ...prevCandidate, status: getCandidateStatusFromStep(step) };
    });

    if (
      candidate.applicationProcess &&
      (!candidate.applicationProcess.firstDayOfWorkAt ||
        !candidate.applicationProcess.position)
    ) {
      setShowHireModal(true);
    } else if (candidate.applicationProcess) {
      await saveStep(candidate, kanbanColumns.HIRED.key, {
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex,
      });
    } else {
      // NOTE: We can't use state values because the method is used inside handleDragEnd and the values are set there
      undoMoveCard(
        sourceColumnIndex,
        sourceCardIndex,
        destinationColumnIndex,
        destinationCardIndex
      );
      toast.error("Error. Candidate has no active hiring process.");
    }
  };

  const handleCloseHire = () => {
    undoMoveCard();
    setShowHireModal(false);
  };

  const handleSavedHire = async (applicationProcess) => {
    if (lastDestinationColumnIndex !== lastSourceColumnIndex) {
      updateCard({
        ...lastCandidate,
        applicationProcess: {
          ...applicationProcess,
          status: applicationProcessStatus.completed,
        },
        status: candidateStatus.HIRED.key,
      });
    } else {
      removeCard(lastSourceColumnIndex, lastSourceCardIndex);
    }
    toast.success("Success! The candidate has been hired.");
    setShowHireModal(false);
  };

  const handleSavedHireError = (message) => {
    toast.error(message);
    undoMoveCard();
    setShowHireModal(false);
  };

  // ######### Move from menu #########
  const handleClickMove = ({ candidate, step, index, status }) => {
    // This object simulates the movement of a card to another column
    const result = {
      destination: {
        droppableId: getStepFromCandidateStatus(status),
        index: 0,
      },
      source: {
        droppableId: step,
        index,
      },
      draggableId: candidate._id,
    };

    handleDragEnd(result);
  };

  /*************************************************
   *           Filters Modal section               *
   *************************************************/

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const handleDeleteBadge = (option) => {
    removeFilter(option);
  };

  /*************************************************
   *        Add Candidate Modal section            *
   *************************************************/

  const [showCandidateModal, setShowCandidateModal] = useState(false);

  const handleClickAddCandidate = () => {
    setShowCandidateModal(true);
  };

  const handleCloseCandidate = () => {
    setShowCandidateModal(false);
  };

  const handleSavedCandidate = (candidate, options) => {
    // Add candidate to the proper column
    const newColumns = [...columns];
    const step = getStepFromCandidateStatus(candidate.status);
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === step
    );
    newColumns[destinationColumnIndex].candidates.unshift(candidate);

    // Set state values
    setColumns(newColumns);
    setLastCandidate(candidate);
    setShowCandidateModal(false);
    options?.addDocuments && setShowUploadDocumentModal(true);
  };

  /*************************************************
   *       Upload Document Modal section           *
   *************************************************/

  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);

  const handleUploadedDocuments = (documents) => {
    // Update candidate
    const updatedCandidate = {
      ...lastCandidate,
      totalDocuments: documents?.length ?? 0,
    };
    const step = getStepFromCandidateStatus(updatedCandidate?.status);
    const destinationColumnIndex = columns.findIndex(
      (column) => column.key === step
    );

    updateCard(updatedCandidate, destinationColumnIndex, 0);
  };

  /*************************************************
   *          Add Application Modal section       *
   *************************************************/
  const [updateAP, setUpdateAP] = useState(false);
  const [showApplicationModal, setShowApplicationModal] = useState(false);

  const handleCloseApplicationModal = () => {
    undoMoveCard();
    setShowApplicationModal(false);
    setUpdateAP(false);
  };

  const handleSaveApplication = async (applicationProcess) => {
    const updatedCandidate = { ...lastCandidate };
    updatedCandidate.applicationProcess = applicationProcess;
    updatedCandidate.status = applicationProcess.steps?.[0].step;
    updatedCandidate.role = applicationProcess?.role;

    updateCard(updatedCandidate);
    setShowApplicationModal(false);
    setUpdateAP(false);
    toast.success("Success! The candidate has been linked to the position.");

    if (lastDestinationColumn === kanbanColumns.OFFER_PROCESS.key) {
      await getCandidateChecklist(updatedCandidate._id);

      setShowDocumentRequestModal(true);
    }
  };

  const handleSaveApplicationError = (message) => {
    toast.error(message);
    undoMoveCard();
    setShowApplicationModal(false);
  };

  /*************************************************
   *           Send Email Modal section            *
   *************************************************/

  const [showEmailSendModal, setShowEmailSendModal] = useState(false);
  const [emailConfig, setEmailConfig] = useState({});
  const handleClickMessage = (candidate) => {
    setEmailConfig({
      candidateId: candidate._id,
      to: candidate.email,
      name: candidate.name,
      role: candidate.applicationProcess?.role,
      company: candidate.applicationProcess?.position?.company?.name,
    });
    setShowEmailSendModal(true);
  };

  /*************************************************
   *    Main render (Kanban - Zero/Error state)    *
   *************************************************/
  const [emptyData, setEmptyData] = useState(false);

  const handleClickRequestDocuments = async (candidate) => {
    setLastCandidate(candidate);
    setDocumentRequestOrigin(DOCUMENT_REQUEST_ORIGINS.OFFER_ACCEPTED_PROCESS);
    setDefaultCheckedItems(DEFAULT_OFFER_ACEPTED_DOCUMENTS);

    await getCandidateChecklist(candidate._id);
    setShowDocumentRequestModal(true);
  };

  useEffect(() => {
    setEmptyData(columns.every((column) => column.candidates?.length === 0));
  }, [columns]);

  useEffect(() => {
    createColumns(
      getQuery({
        search,
        filters,
        status: filterCandidateStatus({ allColumns }),
      })
    );
  }, [search, allColumns, filters, getQuery, createColumns]);

  const mainRender = () => {
    if (loading) return <Loading />;

    if (kanbanError) {
      return (
        <div className="w-100 px-3">
          <StateCard
            imageUrl={assets.GENERIC_ERROR}
            imageWidth={340}
            title="Oops! Looks like something went wrong on your end"
            subtitle="Please refresh and try again"
          >
            <Button
              variant="primary"
              className="AddButton mt-2"
              onClick={() => history.push("/kanban")}
            >
              Refresh Kanban
            </Button>
          </StateCard>
        </div>
      );
    }

    if (emptyData) {
      return (
        <div className="w-100 px-3">
          <StateCard
            imageUrl={assets.ZERO_STATE}
            title="Candidates not found"
          />
        </div>
      );
    }
    return (
      <Col>
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="KanbanBoard">
            {columns.map((column) => {
              return (
                <KanbanColumn
                  key={column.key}
                  step={column.key}
                  userId={userId}
                  candidates={column.candidates}
                  isExternalRecruiter={false}
                  onClickDiscard={handleClickDiscard}
                  onClickMessage={handleClickMessage}
                  onClickHire={handleClickHire}
                  onClickMove={handleClickMove}
                  onClickRequestDocuments={handleClickRequestDocuments}
                />
              );
            })}
          </div>
        </DragDropContext>
      </Col>
    );
  };

  return (
    <>
      <div>
        <SearchBar disabled={loading} onSearch={handleSearch} />

        <Row className="pt-3">
          <Col>
            <h1 className="d-inline me-3">Candidates life-cycle</h1>
            <div>
              <Button variant="link" className="SwitchAllColumns">
                <Form.Check
                  type="switch"
                  name="startEndColumns"
                  id="startEndColumns"
                  label="Show Do not contact / Hired columns"
                  checked={allColumns}
                  onChange={handleShowAllColumns}
                />
              </Button>
            </div>
          </Col>
          <Col className="text-end">
            <Link className="me-2 btn btn-link" to="candidate/historical">
              Historical data
            </Link>

            <Button
              variant="secondary"
              className="AddButton"
              disabled={loading}
              onClick={handleClickAddCandidate}
            >
              Add candidate
            </Button>
          </Col>
        </Row>
        <Row className="pb-3">
          <Col className="d-flex justify-content-between">
            <div>
              <SelectedFiltersV2
                filterValues={selectedFilters}
                icon={faTimes}
                iconPosition="right"
                onClickDeleteBadge={handleDeleteBadge}
              />
            </div>
            <KanbanFilterButton
              filterValues={filters}
              recruiters={recruiters}
              tags={tags}
              loading={loading}
              onApplyFilters={handleApplyFilters}
            />
          </Col>
        </Row>

        <Row>{mainRender()}</Row>
      </div>

      <ApplicationAddModal
        show={showApplicationModal}
        updateAP={updateAP}
        candidate={lastCandidate}
        step={lastDestinationColumn}
        onClose={handleCloseApplicationModal}
        onSave={handleSaveApplication}
        onError={handleSaveApplicationError}
      />

      <CandidateAddModal
        show={showCandidateModal}
        onClose={handleCloseCandidate}
        onSaved={handleSavedCandidate}
      />

      <UploadDocumentModal
        show={showUploadDocumentModal}
        candidateId={lastCandidate?._id}
        isMultiple={true}
        onClose={() => setShowUploadDocumentModal(false)}
        onSaved={handleUploadedDocuments}
      />

      <DiscardModal
        show={showDiscardModal}
        process={PROCESSES.APPLICATION}
        candidateName={lastCandidate?.name}
        candidateStatus={lastCandidate?.status}
        destinationColumn={lastDestinationColumn}
        isLoading={isDiscarding}
        onClose={handleCloseDiscard}
        onSubmit={handleSubmitDiscard}
      />

      <ApplicationOfferAcceptedModal
        show={showOfferAcceptedModal}
        candidate={lastCandidate}
        onClose={handleCloseOfferAccepted}
        onSaved={handleSavedOfferAccepted}
        onError={handleSavedOfferAcceptedError}
      />

      <ApplicationHireModal
        show={showHireModal}
        candidate={lastCandidate}
        onClose={handleCloseHire}
        onSaved={handleSavedHire}
        onError={handleSavedHireError}
      />

      <EmailSendModal
        show={showEmailSendModal}
        emailConfig={emailConfig}
        onClose={() => setShowEmailSendModal(false)}
      />

      <DocumentRequestModal
        show={showDocumentRequestModal}
        candidateId={lastCandidate?._id}
        checklist={candidateChecklist}
        origin={documentRequestOrigin}
        defaultCheckedItems={defaultCheckedItems}
        onClose={() => setShowDocumentRequestModal(false)}
      />
      <AlertContainer />
    </>
  );
};

export default KanbanBoard;
