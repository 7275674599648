import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { CandidateApi, UserApi } from "micoach-api";

import AboutMeCard from "Components/Candidate/Profile/AboutMeCard";
import CertificationModal from "Components/Candidate/Profile/CertificationModal";
import EducationModal from "Components/Candidate/Profile/EducationModal";
import ExperienceModal from "Components/Candidate/Profile/ExperienceModal";
import LanguageModal from "Components/Candidate/Profile/LanguageModal";
import ResumeViewCard from "Components/Candidate/Profile/ResumeViewCard";
import ProfileInterests from "Components/Candidate/ProfileInterests";
import SkillsCard from "Components/Candidate/Skills/SkillsCard";
import DocumentsList from "Components/DocumentsList";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import ProfileCompletionModal from "Components/ProfileBuilder/ProfileCompletionModal";
import ProfileGeneralInformation from "Components/ProfileGeneralInformation";
import UploadDocumentModal from "Components/UploadDocumentModal";
import { UserProfileContext } from "Context/UserProfileProvider";
import { useDocuments } from "Hooks/useDocuments";
import { useQuery } from "Hooks/useQuery";
import { useProfile } from "Hooks/useUsers";
import { getLanguageProficiencyValue } from "utils.js";

import "Screens/styles/ViewProfile.css";

const ViewProfile = () => {
  const queryParams = useQuery();

  const showUploadModal = queryParams.get("showUploadModal") === "true";

  const [generatingResume, setGeneratingResume] = useState(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] =
    useState(showUploadModal);
  const [showProfileCompletionModal, setShowProfileCompletionModal] =
    useState(false);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showCertificationModal, setShowCertificationModal] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedEducation, setSelectedEducation] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const {
    profileCompletion,
    isLoadingProfileCompletion,
    loadProfileCompletion,
  } = useContext(UserProfileContext);
  const { keycloak } = useKeycloak();
  const { profile, loadProfile, loadingProfile, setProfile } = useProfile();
  const location = useLocation();

  const candidateId = keycloak.tokenParsed.candidateId;
  const { documents, setDocuments } = useDocuments(candidateId);

  const handleUploadedDocument = (documents) => {
    setShowUploadDocumentModal(false);
    setDocuments(documents);
  };

  const handleGenerateResume = async () => {
    setGeneratingResume(true);
    try {
      const { data } = await CandidateApi.downloadResumePdf(candidateId, {
        responseType: "arraybuffer",
      });
      saveAs(new Blob([data]), `Resume ${profile.name}.pdf`);
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    } finally {
      setGeneratingResume(false);
    }
  };

  const handleDeleteItem = async (id, items, type) => {
    const newItems = [...items];
    const index = newItems.findIndex((item) => item._id === id);

    if (index === -1) {
      return toast.error("Error, please try again.");
    }

    newItems.splice(index, 1);

    try {
      const response = await UserApi.updateProfile({
        [type]: newItems,
      });
      setProfile(response.data);
      toast.success("Success! The item has been deleted.");
    } catch (error) {
      console.error(error);
      toast.error("Error, please try again.");
    }
  };

  const handleAddOrUpdateExperience = (workExperience = null) => {
    setSelectedExperience(workExperience);
    setShowExperienceModal(true);
  };

  const handleAddOrUpdateEducation = (education = null) => {
    setSelectedEducation(education);
    setShowEducationModal(true);
  };

  const handleAddOrUpdateCertification = (certification = null) => {
    setSelectedCertification(certification);
    setShowCertificationModal(true);
  };

  const handleAddOrUpdateLanguage = (language = null) => {
    setSelectedLanguage(language);
    setShowLanguageModal(true);
  };

  const existsResume = documents.some(
    (document) => document.checklistType === "RESUME"
  );

  useEffect(() => {
    loadProfile();
    loadProfileCompletion();
  }, [loadProfile, loadProfileCompletion]);

  useEffect(() => {
    if (
      !isLoadingProfileCompletion &&
      location.state?.showProfileCompletionModal
    ) {
      setShowProfileCompletionModal(true);
    }
  }, [isLoadingProfileCompletion, location.state]);

  if (loadingProfile)
    return (
      <div className="text-center">
        <Loading />
      </div>
    );
  else if (!profile) return null;

  return (
    <>
      <ProfileCompletionModal
        show={showProfileCompletionModal}
        percentage={profileCompletion}
        onClose={() => setShowProfileCompletionModal(false)}
      />

      <UploadDocumentModal
        checklistType="RESUME"
        allowedExtensions={["pdf", "doc", "docx"]}
        candidateId={candidateId}
        show={showUploadDocumentModal}
        onSaved={handleUploadedDocument}
        onClose={() => setShowUploadDocumentModal(false)}
      />
      <ExperienceModal
        workExperience={selectedExperience}
        workExperienceList={profile.workExperience}
        show={showExperienceModal}
        onSaved={(data) => setProfile(data)}
        onClose={() => setShowExperienceModal(false)}
      />

      <EducationModal
        education={selectedEducation}
        educationList={profile.education}
        show={showEducationModal}
        onSaved={(data) => setProfile(data)}
        onClose={() => setShowEducationModal(false)}
      />

      <CertificationModal
        certification={selectedCertification}
        certificationsList={profile.certifications}
        show={showCertificationModal}
        onSaved={(data) => setProfile(data)}
        onClose={() => setShowCertificationModal(false)}
      />

      <LanguageModal
        language={selectedLanguage}
        languageList={profile.languages}
        show={showLanguageModal}
        onSaved={(data) => setProfile(data)}
        onClose={() => setShowLanguageModal(false)}
      />

      <GoBackButton />

      <h1 className="ScreenTitle">My profile</h1>

      <Row className="ViewProfile">
        <Col md={5}>
          <ProfileGeneralInformation
            profile={profile}
            onSaved={(data) => setProfile(data)}
          />
          <AboutMeCard
            description={profile?.aboutMe}
            onSaved={(data) => setProfile(data)}
          />
          <ProfileInterests
            profile={profile}
            onSaved={(data) => setProfile(data)}
          />
        </Col>
        <Col md={7}>
          <Row className="py-2">
            <Col md={{ span: 4, offset: 4 }}>
              <div className="d-grid gap-2">
                <Button
                  variant="link"
                  size="lg"
                  className="my-2 fs-13"
                  onClick={() => setShowUploadDocumentModal(true)}
                >
                  <FontAwesomeIcon icon={faUpload} className="me-2" />
                  Upload resume
                </Button>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-grid gap-2">
                <Button
                  variant="secondary"
                  size="lg"
                  className="mb-2"
                  disabled={generatingResume}
                  onClick={handleGenerateResume}
                >
                  Generate resume
                </Button>
              </div>
            </Col>
          </Row>
          {existsResume && (
            <Card className="Card">
              <Card.Body>
                <DocumentsList
                  candidateId={candidateId}
                  documents={documents}
                  filterDocuments={["RESUME"]}
                  onDeleted={(updatedDocuments) =>
                    setDocuments(updatedDocuments)
                  }
                />
              </Card.Body>
            </Card>
          )}

          <SkillsCard
            skills={profile?.skills}
            onSaved={(data) => setProfile(data)}
          />

          <ResumeViewCard
            title="Experience"
            buttonName="experience"
            description="Providing details about your work experience allows us to offer you more personalized information."
            onClick={() => handleAddOrUpdateExperience()}
          >
            {profile.workExperience
              ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
              .map((workExperience) => (
                <ResumeViewCard.Item
                  showDuration
                  variantDate="monthYear"
                  itemsGroupType="work experience"
                  key={workExperience._id}
                  id={workExperience._id}
                  current={workExperience.isCurrent}
                  title={workExperience.companyName}
                  subtitle={workExperience.role}
                  startDate={workExperience.startDate}
                  endDate={workExperience.endDate}
                  location={workExperience.location}
                  description={workExperience.description}
                  onClickEdit={() =>
                    handleAddOrUpdateExperience(workExperience)
                  }
                  onConfirmDelete={(id) =>
                    handleDeleteItem(
                      id,
                      profile.workExperience,
                      "workExperience"
                    )
                  }
                />
              ))}
          </ResumeViewCard>
          <ResumeViewCard
            title="Education"
            buttonName="education"
            description="Providing details about your education will reinforce the educational path you have followed."
            onClick={() => handleAddOrUpdateEducation()}
          >
            {profile.education
              ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
              .map((education) => (
                <ResumeViewCard.Item
                  variantDate="monthYear"
                  itemsGroupType="education"
                  key={education._id}
                  id={education._id}
                  current={education.isCurrent}
                  title={education.institution}
                  subtitle={education.degree}
                  startDate={education.startDate}
                  endDate={education.endDate}
                  onClickEdit={() => handleAddOrUpdateEducation(education)}
                  onConfirmDelete={(id) =>
                    handleDeleteItem(id, profile.education, "education")
                  }
                />
              ))}
          </ResumeViewCard>
          <ResumeViewCard
            title="Certifications"
            buttonName="certification"
            description="Providing details about your certifications will highlight strengths in your area of expertise."
            onClick={() => handleAddOrUpdateCertification()}
          >
            {profile.certifications
              ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
              .map((certification) => (
                <ResumeViewCard.Item
                  variantDate="monthYear"
                  itemsGroupType="certifications"
                  key={certification._id}
                  id={certification._id}
                  current={!certification.hasExpiration}
                  title={certification.institution}
                  subtitle={certification.name}
                  startDate={certification.expeditionDate}
                  endDate={certification.expirationDate}
                  itemId={certification.certificationId}
                  url={certification.certificationUrl}
                  urlTitle="View certificate"
                  onClickEdit={() =>
                    handleAddOrUpdateCertification(certification)
                  }
                  onConfirmDelete={(id) =>
                    handleDeleteItem(
                      id,
                      profile.certifications,
                      "certifications"
                    )
                  }
                />
              ))}
          </ResumeViewCard>
          <ResumeViewCard
            title="Languages"
            buttonName="language"
            description="Showing your proficiency in different languages increase the industry opportunities where you can fit."
            onClick={() => handleAddOrUpdateLanguage()}
          >
            {profile.languages
              ?.sort(
                (a, b) =>
                  getLanguageProficiencyValue(b.proficiency) -
                  getLanguageProficiencyValue(a.proficiency)
              )
              .map((language) => (
                <ResumeViewCard.Item
                  itemsGroupType="languages"
                  key={language._id}
                  id={language._id}
                  title={language.language}
                  subtitle={language.proficiency}
                  onClickEdit={() => handleAddOrUpdateLanguage(language)}
                  onConfirmDelete={(id) =>
                    handleDeleteItem(id, profile.languages, "languages")
                  }
                />
              ))}
          </ResumeViewCard>
        </Col>
      </Row>
    </>
  );
};

export default ViewProfile;
