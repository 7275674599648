import { useCallback, useEffect, useState } from "react";
import { CandidateApi } from "micoach-api";

export const useCandidateEvents = () => {
  const [candidateEvents, setCandidateEvents] = useState([]);
  const [loadingCandidateEvents, setLoadingCandidateEvents] = useState(false);

  const loadCandidateEvents = useCallback(async () => {
    setLoadingCandidateEvents(true);
    try {
      const { data } = await CandidateApi.listCandidateEvents();
      setCandidateEvents(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCandidateEvents(false);
    }
  }, []);

  useEffect(() => {
    loadCandidateEvents();
  }, [loadCandidateEvents]);

  return { candidateEvents, loadingCandidateEvents };
};

export const usePublicCandidateEvent = (candiadteId, eventId) => {
  const [candidateEvent, setCandidateEvent] = useState();
  const [loadingCandidateEvent, setLoadingCandidateEvent] = useState(false);

  const loadEvent = useCallback(async () => {
    if (candiadteId && eventId) {
      setLoadingCandidateEvent(true);
      try {
        const { data } = await CandidateApi.getPublicCandidateEvent(
          candiadteId,
          eventId
        );

        setCandidateEvent(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCandidateEvent(false);
      }
    }
  }, [candiadteId, eventId]);

  useEffect(() => {
    loadEvent();
  }, [loadEvent]);

  return { candidateEvent, loadingCandidateEvent, setCandidateEvent };
};
