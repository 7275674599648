import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory, useLocation } from "react-router-dom";

import Achievement from "Components/Candidate/Achievement";
import AchievementCard from "Components/Candidate/AchievementCard";
import StateCard from "Components/Common/StateCard";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import { useAchievements } from "Hooks/useAchievements";
import { useWindowSize } from "Hooks/useWindowSize";
import { assets } from "constants.js";

import "Screens/Candidate/styles/ListAchievements.css";

function ListAchievements() {
  const [selectedAchievement, setSelectedAchievement] = useState(null);
  const [loading, achievements] = useAchievements();
  const history = useHistory();
  const location = useLocation();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (location.state?.selectedAchievement) {
      setSelectedAchievement(location.state.selectedAchievement);
    } else if (achievements && achievements.length) {
      setSelectedAchievement(achievements[0]);
    }
  }, [achievements, location]);

  const handleClickAchievementCard = (achievement) => {
    windowSize.isMobile
      ? history.push(`/achievements/${achievement._id}`)
      : setSelectedAchievement(achievement);
  };

  return (
    <div className="ListAchievements">
      <GoBackButton />
      <h1 className="ScreenTitle">My achievements</h1>

      {loading ? (
        <div className="text-center">
          <Loading />
        </div>
      ) : achievements.length ? (
        <Row>
          <Col md={5}>
            {achievements.map((achievement) => {
              return (
                <AchievementCard
                  achievement={achievement}
                  key={achievement._id}
                  isSelected={selectedAchievement?._id === achievement._id}
                  onClick={handleClickAchievementCard}
                  seeCertificate={windowSize.isMobile}
                />
              );
            })}
          </Col>
          <Col md={7} className="d-none d-md-inline">
            <Card className="Card mt-0">
              <Card.Body>
                <Achievement achievement={selectedAchievement} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <StateCard
          imageUrl={assets.ZERO_STATE}
          title="You don't have any achievements yet!"
        />
      )}
    </div>
  );
}

export default ListAchievements;
