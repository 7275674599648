import { useParams } from "react-router";

import Achievement from "Components/Candidate/Achievement";
import StateCard from "Components/Common/StateCard";
import GoBackButton from "Components/GoBackButton";
import Loading from "Components/Loading";
import { useAchievement } from "Hooks/useAchievements";
import { assets } from "constants.js";

const ViewAchievement = () => {
  const { id } = useParams();
  const [loading, achievement] = useAchievement(id);

  if (loading) {
    return <Loading />;
  }

  if (!achievement) {
    return (
      <StateCard imageUrl={assets.ZERO_STATE} title="Achievement not found!" />
    );
  }

  return (
    <>
      <GoBackButton className="mb-3" />
      <Achievement achievement={achievement} />
    </>
  );
};

export default ViewAchievement;
